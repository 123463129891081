<template>
  <v-dialog v-model="isVisible" width="378">
    <div class="bg-silver">
      <div class="d-flex justify-space-between">
        <div
          class="d-flex justify-center align-self-end align-center mb-n5 w-100"
        >
          <IconTicket color="var(--color__primary)" width="65.25" height="65.27" />
          <h3 class="text-heading --w-bold --prm ml-2">
            {{ $t("menu.main.exchangePoints") }}
          </h3>
        </div>
        <button
          @click.stop="$emit('close')"
          class="btn-close bg-primary flex-shrink-0"
        >
          <div class="btn-close__icon" />
        </button>
      </div>

      <div class="px-8 py-13">
        <v-btn
          elevation="0"
          @click="toOrderTicket"
          rounded
          x-large
          block
          class="mb-3 --prm btn-huge shadow-smoke"
        >
          <span class="mx-auto --w-bold">{{ $t("menu.main.items.orderTickets") }}</span>
          <IconRightBlue class="mx-0"/>
        </v-btn>
        <v-btn
          elevation="0"
          to="/home/exchange-points/history"
          rounded
          x-large
          block
          class="--prm btn-huge shadow-smoke"
        >
          <span class="mx-auto --w-bold">{{ $t("menu.main.items.receptionStatusOrHistory") }}</span>
          <IconRightBlue class="mx-0"/>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IconTicket from '@/components/icons/IconTicket'
import IconRightBlue from '@/components/icons/IconRightBlue'

export default {
  name: 'HomeGiftTicketDialog',
  components: {
    IconTicket,
    IconRightBlue
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  watch: {
    isVisible (value) {
      if (value && !this.$isAllowed().pointExchangeHistory()) {
        this.toOrderTicket()
      }
    }
  },
  methods: {
    toOrderTicket () {
      this.$router.push('/home/exchange-points')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    background-color: var(--color__white) !important;
  }
}
</style>
