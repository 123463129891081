<template>
  <div style="margin-top: -70px">
    <!-- START Top-banner -->
    <v-parallax height="auto" class="parallax">
      <img
        class="parallax-img"
        position="center bottom"
        src="@/assets/images/tokyu-vacations-bg.png"
        lazy-src="@/assets/images/lazy-img.png"
      />
      <img
        class="parallax-img-mob"
        position="center bottom"
        src="@/assets/images/tokyu-vacations-bg-mobile.png"
        lazy-src="@/assets/images/lazy-img.png"
      />
      <div class="d-none d-md-block" />
      <div class="grad pa-2">
        <div class="bg-dark--blur logo-title pt-15 pl-1 mt-md-0">
          <router-link to="/home">
            <Logo class="logo-img" :width="356" :height="80" :light="true" />
          </router-link>
          <h3 class="text--white">
            {{ $t("title.membersOnlySite.subHeader.main")}}
            <span class="no-break" />
            {{ $t("title.membersOnlySite.subHeader.desc") }}
          </h3>
        </div>
      </div>
      <div class="box-right">
        <div
          class="
            first-section
            pos-relative
            main-layer__front
            elevation-3 bg-primary--grad
            pa-4 pa-sm-5
            d-flex
            flex-column
            mx-auto
          "
        >
          <div class="text-title d-flex flex-wrap"><div>{{ $t("common.welcome") }}</div><div>{{ userName }}様</div></div>
          <div>
            <router-link
              class="--white text-decoration-underline mr-2"
              to="/home/download-documents#userguide"
            >
              {{ $t('common.pleaseRead') }}
            </router-link>
            /
            <a
              class="--white text-decoration-underline ml-2"
              :href="faq"
              target="_blank"
            >
              {{ $t('common.faqPage') }}
            </a>
          </div>
          <v-divider class="my-3 d-md-inline w-100" />
          <template v-if="latestReservation">
            <div
              class="
                d-flex
                flex-md-column
                align-center
                justify-center
                align-md-baseline
              "
            >
              <h3 class="latest-heading">
                {{ $t("homePage.latestReservation") }}
              </h3>
              <v-divider vertical class="d-md-none mx-3 w-100" />
              <div class="mb-2 mb-md-0 w-100">
                <div class="reservation-date text-center">
                  <!-- Date From -->
                  <span>
                    {{ latestReservation.dateFrom.getFullYear() }} /
                  </span>
                  <span>{{
                      latestReservation.dateFrom.getMonth() +
                      1 +
                      "/" +
                      latestReservation.dateFrom.getDate()
                    }}</span>
                  <span>
                    ({{ getDayText(latestReservation.dateFrom.getDay()) }})
                  </span>
                  <!-- --------- -->
                  <span>ー</span>
                  <!-- Date To -->
                  <span>{{ latestReservation.dateTo.getDate() }}</span>
                  <span>
                    ({{ getDayText(latestReservation.dateTo.getDay()) }})
                  </span>
                  <!-- ------- -->
                </div>
                <h4
                  class="text-medium--white font-weight-light text-md-center"
                  v-text="latestReservation.name"
                />
              </div>
            </div>
            <!-- Button view QR -->
            <v-btn
              elevation="0"
              :to="'/home/booking/reservation-details/' + latestReservation.id"
              class="btn-icon bg-white mt-4 mb-3 mb-md-0"
              block
              rounded
              x-large
            >
              <span class="text-center w-100">{{ $t("common.homePrecheckin") }}</span>
              <!--<span class="text-normal ml-3">{{$t("buttons.viewQRUsage") }}</span>-->
              <v-icon color="var(--color__primary)">mdi-chevron-right-circle-outline</v-icon>
            </v-btn>
            <!-- -------------- -->
          </template>
        </div>

        <UrgentInformationItem class="urgent-information-container" />
        <div
          class="
          second-section
          main-layer__back main-layer__back__top main-layer__back__bottom-2
          mx-auto
          "
        >
          <div class="d-grid mt-3 pa-4 pa-sm-5 mx-auto">
            <div
              v-for="(item, index) in listMenu"
              :key="item.title"
              :class="{ 'div-rounded mt-2': item.icon == '' }"
              @click="item.click ? item.click() : handleClickMenu(item.link, index)"
            >
              <div v-if="item.icon" class="mt-2" style="height: 46px">
                <IconOpenDoor class="amb-2" v-if="item.icon == 'mdi-door-open'" />
                <IconBellOut
                  class="amb-2"
                  v-if="item.icon == 'mdi-bell-outline'"
                />
                <IconHisList class="amb-2" v-if="item.icon == 'mdi-history'" />
              </div>
              <component :is="item.icon ? 'div' : 'span'" v-html="item.title" class="menu-title"></component>
            </div>
          </div>
        </div>
      </div>
    </v-parallax>
    <!-- END Top-banner -->

    <!-- START List-news -->
    <div class="main-layer__front main-layer__front__top-2 bg-dark-silver">
      <div class="t-container">
        <h2 class="mb-5">
          <span class="text-x-large--prm mr-3">News</span>
          <span class="text-heading--prm text-mobile">お知らせ</span>
        </h2>
        <v-row>
          <v-col cols="12" sm="6" v-for="item in listNews" :key="item.id">
            <NewsItem
              small
              :id="item.id"
              :title="item.title"
              :urlImg="item.imageUrl"
              :description="item.body"
              :showFromDate="item.showFromDate"
            />
          </v-col>
          <v-col cols="12" v-if="listNews.length === 0">
            現在お知らせはございません
          </v-col>
        </v-row>
        <div class="text-right" v-if="listNews.length > 0">
          <v-btn
            elevation="0"
            text
            to="/home/news"
            class="--prm text-medium mt-3"
          >
            {{ $t("buttons.viewMore") }}
          </v-btn>
        </div>
      </div>
    </div>
    <!-- END List-news -->

    <!-- START contact -->
    <div
      class="main-layer__back main-layer__back__top-2 main-layer__back__bottom-2 bg-white"
    >
      <v-container class="py-5">
        <v-row justify="center">
          <v-col xd="12" sm="12" md="6" lg="6" xl="6" class="d-flex flex-wrap group-btns justify-center">
            <div style="max-width: 350px">
              <v-btn
                elevation="0"
                to="/home/download-documents/vacation-life-back-number"
                x-large
                rounded
                block
                class="bg-silver ma-2 --prm btn-icon mx-auto"
              >
                <span class="text-center w-100">{{ $t("buttons.vacationLifeBackNumber") }}</span>
                <v-icon color="var(--color__primary)">mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col xd="12" sm="12" md="6" lg="6" xl="6" class="d-flex align-center justify-center">
            <div class="d-flex align-center justify-center align-stretch">
              <div
                class="--prm-light border-medium border-primary-light ml-auto mr-0 px-3 d-flex align-center justify-center"
              >
                {{ $t("common.contactUs") }}
              </div>
              <div class="pl-2 ml-0 mr-auto">
                <span class="text-mobile">{{ $t("homePage.callCenterDial") }}</span>
                <div class="text-m-heading font-weight-bold --prm-light">
                  <a href="tel:0120-618-109">
                    0120-618-109
                  </a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- END contact -->
    <HomeGiftTicketDialog
      :visible="isOpenGiftTicketDialog"
      @close="isOpenGiftTicketDialog = false"
    />
  </div>
</template>

<script>
import { getDayText, parseDate } from '@/utils/get-date'
import NewsItem from '@/components/NewsItem'
import HomeGiftTicketDialog from './components/HomeGiftTicketDialog'
import IconOpenDoor from '@/components/icons/IconOpenDoor.vue'
import IconBellOut from '@/components/icons/IconBellOut.vue'
import IconHisList from '@/components/icons/IconHisList.vue'
import Logo from '@/components/icons/Logo'
import UrgentInformationItem from '@/components/UrgentInformation'

export default {
  name: 'HomePage',
  components: {
    UrgentInformationItem,
    NewsItem,
    HomeGiftTicketDialog,
    IconOpenDoor,
    IconBellOut,
    IconHisList,
    Logo
  },
  methods: {
    getDayText,
    handleClickMenu (link, index) {
      if (link) {
        this.$router.push(link)
      } else {
        this.isOpenGiftTicketDialog = true

        if (index === 0) {
          this.isOpenDialog = true
        }
      }
    },
    async userGuideList (index) {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          const res = await this.$store.dispatch('getUserGuideList', {
            apollo: this.$apollo
          })
          window.open(res[index].url, '_blank')
        })
      })
    }
  },
  data () {
    return {
      isOpenGiftTicketDialog: false,
      latestReservation: null,
      listMenu: [{
        icon: 'mdi-door-open',
        title: this.$t('menu.main.vacancySearch'),
        link: '/home/vacancy-search'
      },
      {
        icon: 'mdi-history',
        title: this.$t('menu.main.reservationAndUsageHistory'),
        link: '/home/booking'
      },
      {
        icon: 'mdi-bell-outline',
        title: this.$t('menu.main.vacancyNotification'),
        link: '/home/vacancy-notification'
      },
      {
        icon: '',
        title: this.$t('menu.main.exchangePoints'),
        link: null
      },
      {
        icon: '',
        title: this.$t('menu.main.membershipInformation'),
        link: '/home/member-info'
      },
      {
        icon: '',
        title: this.$t('menu.main.contractDetails'),
        link: '/home/contract-information'
      },
      {
        icon: '',
        title: this.$t('homePage.pleaseBeSureToReadIt'),
        link: '/home/download-documents#userguide'
      },
      {
        icon: '',
        title: this.$t('homePage.FAQ'),
        click: () => {
          window.open(this.faq, '_blank')
        }
      }
      ],
      listNews: []
    }
  },
  computed: {
    userName () {
      if (this.$store.state.user.userInfo != null) {
        return this.$store.state.user.userInfo.name
      } else {
        return ''
      }
    },
    faq () {
      return process.env.VUE_APP_FAQ_URL
    },
    isMobile () {
      console.log(this.$vuetify.breakpoint.name)
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
        default: return false
      }
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('loadUserInfo')

        const bookingListRes = await this.$store.dispatch('mostRecentBooking', {
          apollo: this.$apollo
        })
        const reservation = bookingListRes.data.myBookingList.items[0]
        if (reservation) {
          this.latestReservation = {
            dateFrom: parseDate(reservation.checkInDate),
            dateTo: parseDate(reservation.checkOutDate),
            name: reservation.room.roomType.facility.name,
            linkQRcode: '/',
            id: reservation.id
          }
        }

        const webNotificationListRes = await this.$store.dispatch('myWebNotificationList', {
          apollo: this.$apollo,
          take: 4,
          skip: 0
        })
        this.listNews = webNotificationListRes.data.myWebNotificationList.items
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
$header-min-height: 690px;

.div-rounded {
  border-radius: 40px !important;
  height: 50px !important;
  color: var(--color__primary) !important;
}

.parallax {
  min-height: $header-min-height;
}

.parallax-img {
  position: absolute;
  z-index: -1;
}

.parallax-img-mob {
  display: none;
  position: absolute;
  z-index: -1;
}

.box-right {
  height: 100%;
}

.logo-title {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;

  .no-break {
    display: none;
  }
}

.grad svg.logo-img {
  width: 356px;
  height: 80px;
}

@media (min-width: 961px) {
  .box-right {
    padding-top: 90px;
    width: 384px;
  }
  .grad {
    position: absolute;
  }

  ::v-deep .v-parallax__content {
    min-height: $header-min-height;
  }

  .parallax {
    &::v-deep .v-parallax__content {
      width: 100%;
    }
  }

  .logo-title {
    display: block;
    font-size: 18px;
    font-weight: normal;

    h3 {
      margin-left: 12px;
    }
  }

}
@media (min-width: 380px) and (max-width: 960px) {
  .logo-title {
    h3 {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
    }

    .no-break {
      display: block;
    }
  }
}

@media (max-width: 960px) {
  .grad svg.logo-img {
    width: 257px;
    height: 62px;
  }
}

@media (max-width: 900px) {
  .parallax-img {
    display: none;
  }
  .parallax-img-mob {
    display: block;
    width: 100%;
  }
}

.menu-title {
  text-align: center;
  padding: 0 2px;
}
</style>
