<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="39.492" height="42.745" viewBox="0 0 39.492 42.745">
  <g id="Group_4770" data-name="Group 4770" transform="translate(0 0)">
    <g id="Group_4729" data-name="Group 4729" transform="translate(0 0)">
      <g id="Group_4728" data-name="Group 4728" transform="translate(0)">
        <path id="Path_4518" data-name="Path 4518" d="M53.19,9.036A19.6,19.6,0,0,0,40.62,3.3l2.4-2.4-.9-.9L38.1,4.017,42.12,8.035l.9-.9L40.456,4.569A18.471,18.471,0,1,1,20.756,23c0-.41.014-.824.04-1.231l-1.271-.084c-.029.435-.043.877-.043,1.315A19.746,19.746,0,1,0,53.19,9.036Z" transform="translate(-19.482)" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4731" data-name="Group 4731" transform="translate(2.74 11.848)">
      <g id="Group_4730" data-name="Group 4730" transform="translate(0)">
        <path id="Path_4519" data-name="Path 4519" d="M53.01,141.921c-.247.361-.485.734-.708,1.11l1.1.649c.208-.351.431-.7.662-1.038Z" transform="translate(-52.302 -141.921)" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4733" data-name="Group 4733" transform="translate(4.227 9.782)">
      <g id="Group_4732" data-name="Group 4732" transform="translate(0)">
        <path id="Path_4520" data-name="Path 4520" d="M70.965,117.169c-.293.325-.578.663-.848,1.006l1,.788c.253-.321.52-.638.794-.941Z" transform="translate(-70.117 -117.169)" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4735" data-name="Group 4735" transform="translate(0.173 19.076)">
      <g id="Group_4734" data-name="Group 4734" transform="translate(0)">
        <path id="Path_4521" data-name="Path 4521" d="M21.771,228.49c-.086.428-.159.865-.217,1.3l1.263.168c.054-.406.122-.814.2-1.215Z" transform="translate(-21.554 -228.49)" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4737" data-name="Group 4737" transform="translate(5.988 7.949)">
      <g id="Group_4736" data-name="Group 4736">
        <path id="Path_4522" data-name="Path 4522" d="M92.186,95.218c-.333.283-.66.581-.974.885l.888.914c.293-.285.6-.564.911-.828Z" transform="translate(-91.212 -95.218)" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4739" data-name="Group 4739" transform="translate(1.554 14.113)">
      <g id="Group_4738" data-name="Group 4738" transform="translate(0)">
        <path id="Path_4523" data-name="Path 4523" d="M38.653,169.042c-.2.391-.383.793-.554,1.194l1.173.5c.159-.376.333-.752.518-1.117Z" transform="translate(-38.099 -169.042)" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4741" data-name="Group 4741" transform="translate(0.693 16.536)">
      <g id="Group_4740" data-name="Group 4740" transform="translate(0)">
        <path id="Path_4524" data-name="Path 4524" d="M28.175,198.073c-.143.413-.274.836-.389,1.258l1.229.335c.108-.394.23-.79.364-1.176Z" transform="translate(-27.786 -198.073)" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4743" data-name="Group 4743" transform="translate(5.866 8.986)">
      <g id="Group_4742" data-name="Group 4742" transform="translate(0)">
        <path id="Path_4525" data-name="Path 4525" d="M103.715,107.631A13.969,13.969,0,1,0,117.683,121.6,13.985,13.985,0,0,0,103.715,107.631Zm0,26.664A12.695,12.695,0,1,1,116.41,121.6,12.709,12.709,0,0,1,103.715,134.295Z" transform="translate(-89.746 -107.631)" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4745" data-name="Group 4745" transform="translate(19.109 11.746)">
      <g id="Group_4744" data-name="Group 4744" transform="translate(0)">
        <rect id="Rectangle_2137" data-name="Rectangle 2137" width="1.274" height="1.274" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4747" data-name="Group 4747" transform="translate(19.109 32.808)">
      <g id="Group_4746" data-name="Group 4746" transform="translate(0)">
        <rect id="Rectangle_2138" data-name="Rectangle 2138" width="1.274" height="1.274" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4749" data-name="Group 4749" transform="translate(8.578 22.277)">
      <g id="Group_4748" data-name="Group 4748" transform="translate(0)">
        <rect id="Rectangle_2139" data-name="Rectangle 2139" width="1.274" height="1.274" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4751" data-name="Group 4751" transform="translate(29.64 22.277)">
      <g id="Group_4750" data-name="Group 4750" transform="translate(0)">
        <rect id="Rectangle_2140" data-name="Rectangle 2140" width="1.274" height="1.274" fill="#0079c9"/>
      </g>
    </g>
    <g id="Group_4753" data-name="Group 4753" transform="translate(19.109 15.398)">
      <g id="Group_4752" data-name="Group 4752" transform="translate(0)">
        <path id="Path_4526" data-name="Path 4526" d="M249.645,191.476v-7.04h-1.274V192l3.966,3.966.9-.9Z" transform="translate(-248.371 -184.436)" fill="#0079c9"/>
      </g>
    </g>
  </g>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>