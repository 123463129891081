<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 43.629 43.641">
  <g transform="translate(20757 9946.256)">
    <path d="M33.36,8.156,25.937.908a2.3,2.3,0,0,0-3.211.035l-.874.89L19.725.391h0a2.278,2.278,0,0,0-3.154.6L3.648,19.92a.5.5,0,0,0,.824.563l8.059-11.8,8.329,5.651,2.357,1.6L11.9,32.508a1.278,1.278,0,0,1-1.77.337L1.546,27.022a1.277,1.277,0,0,1-.334-1.763l1.767-2.589a.5.5,0,0,0-.824-.563L.387,24.7a2.285,2.285,0,0,0,.6,3.151l8.583,5.823a2.3,2.3,0,0,0,3.154-.6l1.6-2.349a.5.5,0,0,0,.172-.114L27.7,17.162h0l2.312-2.355a.5.5,0,0,0-.712-.7l-1.964,2-1.82-1.777,3.39-4.966a2.25,2.25,0,0,0,.355-1.7c-.01-.051-.022-.1-.035-.151l1-1.02,2.434,2.376a1.3,1.3,0,0,1,.019,1.794l-1.2,1.227a.5.5,0,0,0,.712.7l1.2-1.227a2.284,2.284,0,0,0-.034-3.206ZM27.748,7.04A1.266,1.266,0,0,1,28.082,8.8l-4.3,6.3-3.865-2.622L24.934,5.13ZM17.395,1.554a1.279,1.279,0,0,1,1.771-.337L24.108,4.57l-5.019,7.352-6-4.068Zm6.929,14.525h0l.622-.911,1.692,1.652-9.256,9.427Zm4.413-9.489a2.275,2.275,0,0,0-.427-.375L22.691,2.4l.748-.761a1.3,1.3,0,0,1,1.8-.019l1.146,1.118L29.515,5.8Z" transform="translate(-20745.354 -9946.256) rotate(20)" :fill="color"/>
    <path d="M3.415.087a.5.5,0,0,0-.693.131L.088,4.076a.5.5,0,0,0,.824.563L3.546.78A.5.5,0,0,0,3.415.087Z" transform="translate(-20731.703 -9938.105) rotate(20)" :fill="color"/>
    <path d="M2.73.777A.5.5,0,0,0,1.906.215L.088,2.877a.5.5,0,0,0,.824.563Z" transform="translate(-20730.801 -9935.161) rotate(20)" :fill="color"/>
    <path d="M.218,13.7a.5.5,0,0,0,.693-.131L9.644.777A.5.5,0,0,0,8.82.215L.087,13.007a.5.5,0,0,0,.131.693Z" transform="translate(-20743.043 -9929.349) rotate(20)" :fill="color"/>
    <path d="M6.046.215.088,8.942a.5.5,0,0,0,.824.563L6.871.777A.5.5,0,0,0,6.046.215Z" transform="translate(-20742.846 -9923.329) rotate(20)" :fill="color"/>
    <path d="M.489,6.674A.5.5,0,0,0,.9,6.456L4.779.777A.5.5,0,0,0,3.955.215L.078,5.894a.516.516,0,0,0,.412.78Z" transform="translate(-20740.059 -9929.884) rotate(20)" :fill="color"/>
    <path d="M4.7.354A2.062,2.062,0,0,0,1.845.9L.375,3.049c-1.5,2.2,1.9,4.509,3.4,2.31L5.243,3.207A2.07,2.07,0,0,0,4.7.354ZM4.419,2.645,2.949,4.8A1.057,1.057,0,0,1,1.2,3.612L2.669,1.46A1.057,1.057,0,0,1,4.419,2.645Z" transform="translate(-20748.865 -9925.719) rotate(20)" :fill="color"/>
    <path d="M.216.916l1.22.828A.5.5,0,0,0,2,.917L.776.09A.5.5,0,0,0,.216.916Z" transform="translate(-20728.738 -9928.159) rotate(20)" :fill="color"/>
    <path d="M.216.916l1.22.827c.515.349,1.106-.456.56-.826L.776.09A.5.5,0,0,0,.216.916Z" transform="translate(-20727.348 -9929.158) rotate(20)" :fill="color"/>
    <path d="M.216.916l1.22.827A.5.5,0,0,0,2,.917L.776.09A.5.5,0,0,0,.216.916Z" transform="translate(-20725.955 -9930.157) rotate(20)" :fill="color"/>
    <path d="M.216.916l1.22.827c.515.35,1.106-.456.56-.826L.776.09A.5.5,0,0,0,.216.916Z" transform="translate(-20724.564 -9931.156) rotate(20)" :fill="color"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'IconTicket',
  props: {
    width: {
      type: String,
      default: '43.629'
    },
    height: {
      type: String,
      default: '43.641'
    },
    color: {
      type: String,
      default: '#e8ebef'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
