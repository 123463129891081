<template>
  <svg id="Сгруппировать_4979" data-name="Сгруппировать 4979" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Сгруппировать_4801" data-name="Сгруппировать 4801">
      <path id="Контур_4463" data-name="Контур 4463" d="M828.041-2444l3.043,3.29-3.043,3.263" transform="translate(-818.875 2450.78)" fill="none" stroke="#0079c9" stroke-linecap="round" stroke-width="2"/>
      <g id="Эллипс_781" data-name="Эллипс 781" fill="none" stroke="#0079c9" stroke-width="2">
        <circle cx="10" cy="10" r="10" stroke="none"/>
        <circle cx="10" cy="10" r="9" fill="none"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'IconRightBlue'
}
</script>

<style scoped>

</style>
