<template>
  <svg id="Group_4766" data-name="Group 4766" xmlns="http://www.w3.org/2000/svg" width="39.45" height="39.45" viewBox="0 0 39.45 39.45">
  <g id="Group_4715" data-name="Group 4715" transform="translate(0 0)">
    <path id="Path_4483" data-name="Path 4483" d="M424.929,19.863A.771.771,0,0,0,425.6,19a19.3,19.3,0,0,0-5.836-11.537A.771.771,0,1,0,418.7,8.582a17.748,17.748,0,0,1,5.37,10.612A.771.771,0,0,0,424.929,19.863Z" transform="translate(-386.154 -6.77)" fill="#0079c9"/>
    <path id="Path_4484" data-name="Path 4484" d="M65.4,59.1a.771.771,0,0,0,.888-.632,13.163,13.163,0,0,1,3.952-7.358.77.77,0,1,0-1.055-1.123,14.714,14.714,0,0,0-4.416,8.225A.77.77,0,0,0,65.4,59.1Z" transform="translate(-59.753 -46.014)" fill="#0079c9"/>
    <path id="Path_4485" data-name="Path 4485" d="M375.05,58.472a.771.771,0,0,0,1.52-.256,14.715,14.715,0,0,0-4.416-8.225.771.771,0,1,0-1.056,1.123A13.166,13.166,0,0,1,375.05,58.472Z" transform="translate(-342.225 -46.016)" fill="#0079c9"/>
    <path id="Path_4486" data-name="Path 4486" d="M80.511,40.448a3.086,3.086,0,0,0,3.082-3.082V35.825H94.38a.77.77,0,0,0,.771-.771V30.7a.77.77,0,0,0-.723-1.035H94.38a3.857,3.857,0,0,1-3.853-3.853V15.638a10.024,10.024,0,0,0-6.935-9.532V4.08a3.082,3.082,0,0,0-6.164,0V6.1a10.012,10.012,0,0,0-6.935,9.533V25.808a3.864,3.864,0,0,1-3.813,3.852h-.04a.77.77,0,0,0-.771.77v4.623a.77.77,0,0,0,.771.771H77.429v1.541A3.086,3.086,0,0,0,80.511,40.448Zm1.541-3.082a1.541,1.541,0,1,1-3.082,0V35.825h3.082ZM80.511,2.539A1.543,1.543,0,0,1,82.052,4.08V5.74a10.2,10.2,0,0,0-3.082,0V4.08A1.543,1.543,0,0,1,80.511,2.539ZM67.412,31.2h9.679a.771.771,0,1,0,0-1.541H70.4a5.378,5.378,0,0,0,1.631-3.853V15.638a8.47,8.47,0,0,1,6.356-8.209,8.585,8.585,0,0,1,4.239,0,8.483,8.483,0,0,1,6.356,8.209V25.808a5.377,5.377,0,0,0,1.624,3.853H84.026a.771.771,0,0,0,0,1.541h9.584v3.082h-26.2Z" transform="translate(-60.786 -0.998)" fill="#0079c9"/>
    <path id="Path_4487" data-name="Path 4487" d="M6.908,8.543A.771.771,0,1,0,5.845,7.427,19.3,19.3,0,0,0,.005,18.967a.77.77,0,1,0,1.529.191A17.757,17.757,0,0,1,6.908,8.543Z" transform="translate(0.001 -6.734)" fill="#0079c9"/>
    <path id="Path_4488" data-name="Path 4488" d="M247.681,373.042a.771.771,0,1,1-.771-.77A.771.771,0,0,1,247.681,373.042Z" transform="translate(-227.137 -343.609)" fill="#0079c9"/>
  </g>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>
