<template>
  <div class="w-100">
    <div
      class="urgent-info d-flex mt-1 pa-3"
      v-for="(item, i) in urgentInformation"
      :key="i"
      :class="{
        'first-item': i === 0,
        [item.cssClass]: true
      }"
      @click="() => item.click ? item.click() : null"
    >
      <v-icon>mdi-alert-circle-outline</v-icon>
      <div
        class="d-flex flex-column flex-md-row align-baseline align-md-center ml-2"
      >
        <template v-if="!item.hideTitle">
          <h4 class="font-weight-medium">
            {{ $t("newsPage.urgentInformation") }}
          </h4>
          <v-divider vertical class="d-none d-md-block mx-4">|</v-divider>
        </template>
        <p class="font-weight-light text-caption text-md-body-2">
          <template v-if="item.title">{{ item.title }} - </template><format-span :value="item.body" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UrgentInformation',
  data () {
    return {
      urgentNews: []
    }
  },
  computed: {
    urgentInformation () {
      const info = [
        ...this.urgentNews
      ]

      if (this.$store.getters.cardExpireMonths < 3 && this.$isMainUser()) {
        const cardNews = {
          hideTitle: true,
          title: '',
          body: '',
          cssClass: 'cc-warning',
          click: () => {
            this.$router.push({ name: 'home-member-info-credit-card' })
          }
        }
        cardNews.body = this.$store.getters.cardExpireMonths < 0
          ? 'ご登録いただいているクレジットカードの有効期限が切れております。ここをクリックしてクレジットカードの変更をお願いいたします。'
          : 'ご登録いただいているクレジットカードの有効期限が近付いております。ここをクリックしてクレジットカードの変更をお願いいたします。'

        info.unshift(cardNews)
      }

      return info
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        const data = await this.$store.dispatch('myWebNotificationListUrgent', {
          apollo: this.$apollo,
          take: 3,
          skip: 0
        })

        this.urgentNews = data.data.myWebNotificationList.items
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~/src/assets/styles/modules/_variables-scss.scss';
.urgent-info {
  background-color: var(--color__pink);

  &.cc-warning {
    color: $color__red-bright;
    background-color: rgba($color__yellow, 1);
  }

  & ::v-deep .theme--light.v-icon {
    color: unset;
  }
}
</style>
