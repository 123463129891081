<template>
  <div class="shadow-smoke py-3 px-4 py-md-5" :class="{ 'small-item': small }">
    <div class=" --c-prm">{{ showFromDate | dotDate }}</div>
    <router-link
      :to="linkDetail+this.id"
      class="text-medium--prm line--1 font-weight-medium title-sm"
    >
      {{ title }}
    </router-link>
    <div class="d-flex">
      <router-link :to="linkDetail+this.id">
        <v-img
          :src="getImage(urlImg)"
          aspect-ratio="1"
          :lazy-src="lazyImgUrl"
          class="rounded mr-3"
          :class="{'small-img':small}"
          alt="tokyu_vacations_news"
        />
      </router-link>
      <div>
        <router-link
          v-if="!small"
          :to="linkDetail+this.id"
          class="text-medium--prm line--1 font-weight-medium title-md"
        >
          {{ title }}
        </router-link>
        <p class="line--3 word-break"><format-span :value="description" /></p>
      </div>
    </div>
    <div class="text-right mt-2 d-md-none">
      <v-btn
        elevation="0"
        :to="linkDetail + this.id"
        text
        color="var(--color__primary)"
        class="pr-0 pl-2"
      >
        {{ $t("buttons.clickHereForDetails") }}
        <v-icon>mdi-menu-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { lazyImageMixin } from '@/mixins/lazy-image.mixin'

export default {
  name: 'NewsItem',
  mixins: [lazyImageMixin],
  props: {
    id: [Number, String],
    title: {
      type: String,
      default: ''
    },
    urlImg: String,
    description: {
      type: String,
      default: ''
    },
    showFromDate: String,
    small: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      linkDetail: '/home/news/'
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../../assets/styles/modules/variables-scss' as v;

div.shadow-smoke {
  background-color: var(--color__white);
  margin: 0 auto;
  .text-medium--prm {
    margin-bottom: 10px;
    &:hover {
      color: var(--color__dark-blue) !important;
    }
  }
}
.title-md {
  display: none;
}
.small-item {
  .title {
    &-sm {
      display: -webkit-box !important;
    }
  }
}
::v-deep {
  .v-image {
    width: 60px;
    max-width: 60px;
    align-self: flex-start;
    &:hover {
      opacity: 0.8;
    }
  }
  @media (min-width: v.$breakpoint__md) {
    .v-image {
      width: 108px;
      max-width: 108px;
      &.small-img {
        width: 60px;
        height: 60px;
      }
    }
    .title {
      &-sm {
        display: none;
      }
      &-md {
        display: -webkit-box;
      }
    }
  }
}
</style>
