<template>
  <svg id="Group_4765" data-name="Group 4765" xmlns="http://www.w3.org/2000/svg" width="46.81" height="46.879" viewBox="0 0 46.81 46.879">
  <path id="Path_4502" data-name="Path 4502" d="M411.723,269.5a.67.67,0,0,1-.67-.67v-1.989a.67.67,0,1,1,1.339,0v1.989A.67.67,0,0,1,411.723,269.5Z" transform="translate(-372.641 -241.745)" fill="#0079c9"/>
  <path id="Path_4503" data-name="Path 4503" d="M190.8,60.682a.67.67,0,0,1-.67-.67V14.476a.67.67,0,0,1,.666-.673.684.684,0,0,1,.07,0l21.382,2.163a.67.67,0,0,1,.6.67V27.574a.67.67,0,0,1-1.339,0V17.244l-20.043-2.028V59.246l20.043-2.715V45.575a.67.67,0,0,1,1.339,0V57.113a.67.67,0,0,1-.579.664l-21.382,2.9a.689.689,0,0,1-.09.006Z" transform="translate(-173.102 -13.803)" fill="#0079c9"/>
  <path id="Path_4504" data-name="Path 4504" d="M411.723,200.892a.67.67,0,0,1-.67-.67v-1.949a.67.67,0,1,1,1.339,0v1.949A.67.67,0,0,1,411.723,200.892Z" transform="translate(-372.641 -179.815)" fill="#0079c9"/>
  <path id="Path_4505" data-name="Path 4505" d="M88.257,78.237a.67.67,0,0,1-.67-.67V36.648a.67.67,0,0,1,.67-.67h9.924a.67.67,0,0,1,0,1.339H88.926v40.25A.67.67,0,0,1,88.257,78.237Z" transform="translate(-80.482 -33.832)" fill="#0079c9"/>
  <path id="Path_4506" data-name="Path 4506" d="M127.335,114.74a.67.67,0,0,1-.67-.67V77.062a.67.67,0,0,1,.67-.67h6.137a.67.67,0,0,1,0,1.339H128V114.07A.67.67,0,0,1,127.335,114.74Z" transform="translate(-115.778 -70.334)" fill="#0079c9"/>
  <path id="Path_4507" data-name="Path 4507" d="M31.871,460.105H14.847a.67.67,0,1,1,0-1.339H31.871a.67.67,0,0,1,0,1.339Z" transform="translate(-14.177 -415.7)" fill="#0079c9"/>
  <path id="Path_4508" data-name="Path 4508" d="M388.991,460.105H378.74a.67.67,0,1,1,0-1.339h10.251a.67.67,0,1,1,0,1.339Z" transform="translate(-342.85 -415.7)" fill="#0079c9"/>
  <path id="Path_4509" data-name="Path 4509" d="M222.483,235.835a2.42,2.42,0,1,1,2.42-2.42h0A2.423,2.423,0,0,1,222.483,235.835Zm0-3.5a1.081,1.081,0,1,0,1.081,1.081h0A1.082,1.082,0,0,0,222.483,232.334Z" transform="translate(-200.136 -209.974)" fill="#0079c9"/>
</svg>

</template>

<script>
export default {

}
</script>
